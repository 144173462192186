import { Page, View } from "@react-pdf/renderer"
import Decimal from "decimal.js"
import { isEmpty, isNil } from "lodash"
import React, { CSSProperties, FC, memo, useCallback, useMemo } from "react"
import ReactDOMServer from "react-dom/server"
import Html from "react-pdf-html"
import { Statement } from "../../../../../../@types/organization"
import FirstPageFixedFooter from "./FirstPageFixedFooter"
import FirstPageFooter, { PastDueWarningLevel } from "./FirstPageFooter"
import FirstPageHeader from "./FirstPageHeader"
import FirstPageMainSection from "./FirstPageMainSection"
import TotalsTable from "./TotalsTable"

const StatementFirstPage: FC<Statement> = ({facility, items, amountDue, billableLateFeeAmount, companyAddress}) => {
  const columnWidth = {
    date: 100,
    amount: 90,
    number: 60,
    paymentAmount: 120,
  }

  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const statementTableTitle: CSSProperties = {
    marginBottom: 2,
    fontSize: 12
  }

  const statementRow: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    fontSize: 10,
    borderLeft: '0.5px solid #000',
    borderRight: '0.5px solid #000',
    width: '100%',
  }

  const statementRowLast: CSSProperties = {
    borderBottom: `1px solid #000`,
  }

  const statementHighlightRow: CSSProperties = {
    backgroundColor: '#CCC',
    borderTop: '1px solid #000',
  }

  const statementRowOdd: CSSProperties = {
    backgroundColor: '#EEE',
  }

  const statementCell: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    borderLeft: '0.5px solid #000',
    borderRight: '0.5px solid #000',
    minHeight: 15,
  }

  const statementCellCenter: CSSProperties = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const statementCellFirst = {
    flex: 1,
  }

  const statementCellRight: React.CSSProperties = {
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  }

  const statementCellTop: React.CSSProperties = {
    alignItems: "flex-start"
  }

  const fixedFooterViewStyle: any = {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20
  }

  const statementSubRowContainer: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const statementSubRow: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const statementSubLine: React.CSSProperties = {
    borderLeft: '0.5px solid #000',
    borderRight: '0.5px solid #000',
    width: 350,
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }

  const statementAdjustmentsSubLine: React.CSSProperties = {
    ...statementSubLine,
    width: columnWidth.date + 2 * columnWidth.paymentAmount,
  }

  const statementTotalContainerStyle: CSSProperties = {
    width: '100%',
    marginTop: 10,
    textAlign: 'right',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  }

  const statementTotalStyle: CSSProperties = {
    fontSize: '16px',
    textAlign: 'right',
  }

  const formatCurrency = useCallback((value) => {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)
  }, [])

  const {invoices, payments} = useMemo(() => ({
    invoices: items.filter(item => item.type === 'invoice'),
    payments: items.filter(item => item.type === 'payment'),
  }), [items])

  const {invoicesTotal, paymentsTotal} = useMemo(() => ({
    invoicesTotal: invoices.reduce((result, item) => {
      return new Decimal(result).plus(item.balance).toNumber()
    }, 0.00),
    paymentsTotal: payments.reduce((result, item) => {
      return new Decimal(result).plus(item.balance).toNumber()
    }, 0.00),
  }), [invoices, payments])

  const [ageTotals, pastDueTotals, pastDueWarningLevel] = useMemo((): [string[], string[], PastDueWarningLevel] => {
    const formatCurrency = (value) => {
      return Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value)
    }

    const [newAgeTotals, newPastDueTotals] = invoices.reduce((result, item) => {
      if (!isEmpty(item.balance.toString()) && !isNaN(item.balance) && new Decimal(item.balance).greaterThan(0)) {
        // calc the age totals
        if (!item.age || item.age <= 30) {
          result[0][0] = new Decimal(result[0][0]).plus(item.balance).toNumber()
        } else if (item.age <= 60) {
          result[0][1] = new Decimal(result[0][1]).plus(item.balance).toNumber()
        } else if (item.age <= 90) {
          result[0][2] = new Decimal(result[0][2]).plus(item.balance).toNumber()
        } else {
          result[0][3] = new Decimal(result[0][3]).plus(item.balance).toNumber()
        }

        // calc the days overdue
        const daysOverdue = parseInt(item.daysOverdue ?? '0')

        if (daysOverdue > 0) {
          if (daysOverdue <= 30) {
            result[1][1] = new Decimal(result[1][1]).plus(item.balance).toNumber()
          } else if (daysOverdue <= 60) {
            result[1][2] = new Decimal(result[1][2]).plus(item.balance).toNumber()
          } else if (daysOverdue <= 90) {
            result[1][3] = new Decimal(result[1][3]).plus(item.balance).toNumber()
          } else {
            result[1][4] = new Decimal(result[1][4]).plus(item.balance).toNumber()
          }
        } else {
          result[1][0] = new Decimal(result[1][0]).plus(item.balance).toNumber()
        }
      }

      return result
    }, [[0.00, 0.00, 0.00, 0.00], [0.00, 0.00, 0.00, 0.00, 0.00]])

    return [
      newAgeTotals.map((total) => total > 0 ? formatCurrency(total) : ' '),
      newPastDueTotals.map((total) => total > 0 ? formatCurrency(total) : ' '),
      newAgeTotals[3] > 0 ? 3 : newAgeTotals[2] > 0 ? 2 : newAgeTotals[1] > 0 ? 1 : 0,
    ]
  }, [invoices])

  return (
    <Page size='LETTER' orientation="portrait" style={{ paddingVertical: 20, paddingHorizontal: 8, paddingBottom: 50 }}>
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageHeader
              termDesc={facility["terms.details.termDesc"]} 
              facilityTimeZone={facility['ianaTimeZone']}
            />
          )}
        </Html>
      </View>

      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageMainSection
              customer_name={facility.facilityName}
              customer_street={facility.street}
              customer_city={facility.city}
              customer_state={facility.state}
              customer_zip={facility.zip}
            />
          )}
        </Html>
      </View>

      {invoices.map((item, index, array) => {
        return (
          <View wrap={false} key={item.reference}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                <html>
                  <body style={bodyStyle}>
                    {index === 0 ? (
                      <div style={statementTableTitle}>{`INVOICES:`}</div>
                    ) : null}

                    {index === 0 ? (
                      <div style={{
                        ...statementRow,
                        ...statementHighlightRow,
                      }}>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          ...statementCellFirst,
                        }}>Bill Date</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.date,
                        }}>Reference</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.amount,
                        }}><div>Invoice Amount</div></div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.date,
                        }}>Due Date</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.number,
                        }}>Days<br />Outstanding</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.amount,
                        }}><div>Payments Applied</div></div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.amount,
                        }}><div>Balance</div></div>
                      </div>
                    ) : null}

                    <div style={{
                      ...statementRow,
                      ...(index % 2 > 0 ? statementRowOdd : {}),
                    }}>
                      <div style={{
                        ...statementCell,
                        ...statementCellFirst,
                        ...statementCellTop,
                      }}>{item.billDate}</div>
                      <div style={statementSubRowContainer}>
                        <div style={statementSubRow}>
                          <div style={{
                            ...statementCell,
                            ...statementCellCenter,
                            width: columnWidth.date,
                          }}>{item.reference}</div>
                          <div style={{
                            ...statementCell,
                            ...statementCellRight,
                            width: columnWidth.amount,
                          }}>
                            <div>{!isEmpty(item.charge) ? formatCurrency(item.charge) : ` `}</div>
                          </div>
                          <div style={{
                            ...statementCell,
                            ...statementCellCenter,
                            width: columnWidth.date,
                          }}>{item.dueDate}</div>
                          <div style={{
                            ...statementCell,
                            ...statementCellCenter,
                            width: columnWidth.number,
                          }}><div>{item.daysOutstanding ?? ` `}</div></div>
                        </div>
                        {!isNil(item.attachedPayments) && !isEmpty(item.attachedPayments) ? (
                          <div style={{
                            ...statementSubRow,
                            ...statementSubLine,
                          }}>
                            <div>Applied Payments:</div>
                            {item.attachedPayments.map((transaction) => {
                              return (
                                <div key={transaction.reference} style={{fontSize: 8}}>{`- Reference or Check #: ${transaction.reference}, Payment Amount: ${formatCurrency(transaction.amount)}, Applied Amount to this Invoice: ${formatCurrency(transaction.appliedAmount)}`}</div>
                              )
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div style={{
                        ...statementCell,
                        ...statementCellRight,
                        ...statementCellTop,
                        width: columnWidth.amount,
                      }}>
                        <div>{!isEmpty(item.credit) ? formatCurrency(item.credit) : ` `}</div>
                      </div>
                      <div style={{
                        ...statementCell,
                        ...statementCellRight,
                        width: columnWidth.amount,
                        ...statementCellTop,
                      }}>
                        <div>{formatCurrency(item.balance)}</div>
                      </div>
                    </div>
                  </body>
                </html>
              )}
            </Html>
          </View>
        )
      })}

      {payments.map((item, index, array) => {
        return (
          <View wrap={false} key={item.reference}>
            <Html resetStyles>
              {ReactDOMServer.renderToStaticMarkup(
                <html>
                  <body style={bodyStyle}>
                    {index === 0 ? (
                      <div style={{
                        ...statementTableTitle,
                        marginTop: 10,
                      }}>{`PAYMENTS:`}</div>
                    ) : null}

                    {index === 0 ? (
                      <div style={{
                        ...statementRow,
                        ...statementHighlightRow,
                      }}>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          ...statementCellFirst,
                        }}>Reference / Check #</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.date,
                        }}>Date</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.paymentAmount,
                        }}><div>Payment Amount</div></div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.paymentAmount,
                        }}><div>Amount Applied<br />to Invoices</div></div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.amount,
                        }}><div>Unapplied Balance</div></div>
                      </div>
                    ) : null}

                    <div style={{
                      ...statementRow,
                      ...(index % 2 > 0 ? statementRowOdd : {}),
                    }}>
                      <div style={{
                        ...statementCell,
                        ...statementCellFirst,
                        ...statementCellTop,
                      }}>{item.reference}</div>
                      <div style={statementSubRowContainer}>
                        <div style={statementSubRow}>
                          <div style={{
                            ...statementCell,
                            ...statementCellCenter,
                            width: columnWidth.date,
                          }}>{item.billDate}</div>
                          <div style={{
                            ...statementCell,
                            ...statementCellRight,
                            width: columnWidth.paymentAmount,
                          }}>
                            <div>{!isEmpty(item.credit) ? formatCurrency(item.credit) : ` `}</div>
                          </div>
                          <div style={{
                            ...statementCell,
                            ...statementCellRight,
                            ...statementCellTop,
                            width: columnWidth.paymentAmount,
                          }}>
                            <div>{!isEmpty(item.charge) ? formatCurrency(item.charge) : ` `}</div>
                          </div>
                        </div>
                        {!isNil(item.adjustments) && !isEmpty(item.adjustments) ? (
                          <div style={{
                            ...statementSubRow,
                            ...statementAdjustmentsSubLine,
                          }}>
                            <div>Adjustments:</div>
                            {item.adjustments.map((adjustment) => {
                              return (
                                <div key={adjustment.reference} style={{fontSize: 8}}>{`- Reference #: ${adjustment.reference}, Amount: ${formatCurrency(adjustment.amount)}${adjustment.description && adjustment.description.trim().length > 0 ? ` - ${adjustment.description}` : ''}`}</div>
                              )
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div style={{
                        ...statementCell,
                        ...statementCellRight,
                        width: columnWidth.amount,
                        ...statementCellTop,
                      }}>
                        <div>{formatCurrency(item.balance)}</div>
                      </div>
                    </div>

                    {index === array.length - 1 ? (
                      <div style={{
                        ...statementRow,
                        ...((index + 1) % 2 > 0 ? statementRowOdd : {}),
                      }}>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          ...statementCellFirst,
                        }}>{` `}</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.date,
                        }}>{` `}</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.paymentAmount,
                        }}>
                          <div>{` `}</div>
                        </div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.paymentAmount,
                        }}>
                          <div>{` `}</div>
                        </div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.amount,
                        }}>
                          <div>{` `}</div>
                        </div>
                      </div>
                    ) : null}

                    {index === array.length - 1 ? (
                      <div style={{
                        ...statementRow,
                        ...((index + 2) % 2 > 0 ? statementRowOdd : {}),
                        ...statementRowLast,
                      }}>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          ...statementCellFirst,
                        }}>{` `}</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellCenter,
                          width: columnWidth.date,
                        }}>{` `}</div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.paymentAmount,
                        }}>
                          <div>{` `}</div>
                        </div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.paymentAmount,
                        }}><div>{`Total Payments`}</div></div>
                        <div style={{
                          ...statementCell,
                          ...statementCellRight,
                          width: columnWidth.amount,
                        }}>
                          <div>{formatCurrency(paymentsTotal)}</div>
                        </div>
                      </div>
                    ) : null}

                    {index === array.length - 1 ? (
                      <div style={statementTotalContainerStyle}>
                        <div style={statementTotalStyle}><strong>{`Amount Due: ${formatCurrency(amountDue)}`}</strong></div>
                      </div>
                    ) : null}
                  </body>
                </html>
              )}
            </Html>
          </View>
        )
      })}

      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <TotalsTable totals={ageTotals} currentBalance={formatCurrency(invoicesTotal)} billableLateFeeAmount={formatCurrency(billableLateFeeAmount ?? 0.00)} />
          )}
        </Html>
      </View>
      
      <View wrap={false}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageFooter
              pastDueWarningLevel={pastDueWarningLevel}
            />
          )}
        </Html>
      </View>

      <View wrap={false} fixed style={fixedFooterViewStyle}>
        <Html resetStyles>
          {ReactDOMServer.renderToStaticMarkup(
            <FirstPageFixedFooter companyAddress={companyAddress} />
          )}
        </Html>
      </View>
    </Page>
  )
}

export default memo(StatementFirstPage)
