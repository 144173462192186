import moment from "moment"
import { CSSProperties, memo } from "react"

const FirstPageDocumentInfo = ({
  professionalFullName, 
  street,
  city,
  state,
  zip,
  professionalId,
  firstDate,
  lastDate,
  jobTitle,
}) => {
  const bodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  }
  
  const paymentHeaderParagraphyStyle: CSSProperties = {
    margin: 0,
    padding: 0,
    fontSize: '10px',
    marginBottom: 4
  }

  const shiftDatesString = `${moment(firstDate, 'YYYY-MM-DD').format('MM-DD-YYYY')} - ${moment(lastDate, 'YYYY-MM-DD').format('MM-DD-YYYY')}`

  return (
    <html>
      <body style={bodyStyle}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <div style={paymentHeaderParagraphyStyle}>{`Independent Contractor Name: ${professionalFullName}`}</div>
            <div style={paymentHeaderParagraphyStyle}>{`Address: ${street}, ${city}, ${state} ${zip}`}</div>
          </div>

          <div style={paymentHeaderParagraphyStyle}>{`Bravo Care ID: ${professionalId}`}</div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <div style={paymentHeaderParagraphyStyle}>{`Job Title: ${jobTitle}`}</div>
          </div>

          <div>
            <div style={paymentHeaderParagraphyStyle}>{`Pay Dates: ${shiftDatesString}`}</div>
          </div>
        </div>
      </body>
    </html>
  )
}

export default memo(FirstPageDocumentInfo)
