import { ChakraProvider } from '@chakra-ui/react';
import { FC } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import FacilityInvoicePDF from './views/FacilityInvoicePDF';
import FacilityLateChargesInvoicePDF from './views/FacilityLateChargesInvoicePDF';
import TrainingForm from './views/Forms/TrainingForm';
import W9Form from './views/Forms/W9';
import { StatementPDFGenerator } from './views/OrganizationStatementPDF';
import PayHistoryPDF from './views/PayHistoryPDF';
import { PayStubPDFGenerator } from './views/PayStubPDF'
import FluForm from './views/Forms/FluForm'

const router = createBrowserRouter([
  {
    path: '/',
    element: <div></div>
  },
  {
    path: '/facility/invoice',
    element: <FacilityInvoicePDF />,
  },
  {
    path: '/facility/late-charges-invoice',
    element: <FacilityLateChargesInvoicePDF />,
  },
  {
    path: '/organization/statement',
    element: <StatementPDFGenerator />
  },
  {
    path: '/professional/pay-history',
    element: <PayHistoryPDF />,
  },
  {
    path: '/professional/pay-stub',
    element: <PayStubPDFGenerator />,
  },
  {
    path: '/forms/w9',
    element: <W9Form />
  },
  {
    path: '/forms/flu',
    element: <FluForm />
  },
  {
    path: '/forms/training-form',
    element: <TrainingForm />
  }
]);

const App: FC = () => {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  )
}

export default App;
