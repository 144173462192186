import { isNil } from "lodash"
import { memo } from "react"
import { formatCurrency } from "../../../../../helpers/number.helper"
import { bodyStyle, columnWidth, payStubSection, paymentDetailsCenteredTableCell, paymentDetailsTableCell, paymentDetailsTableFirstCell, paymentDetailsTableGrayRow, paymentDetailsTableHightlightRow, paymentDetailsTableTotalsRow, paymentDetailsTableWhiteRow } from "../../../styles"

const PayStubPaymentsTable = ({payment, totalAmount, index, array}) => {
  const getRowStyles = (index) => {
    return index % 2 === 0 ? paymentDetailsTableWhiteRow : paymentDetailsTableGrayRow
  }

  const getFee = () => {
    if (payment.payment_transaction_fee <= 0) {
      return ''
    }

    return `${
      payment.payment_transaction_fee < 0
        ? '-'
        : ''
    }$${formatCurrency(payment.payment_transaction_fee)}`
  }

  const getPayAmount = () => {
    if (payment.amount <= 0) {
      return '$0.00'
    }

    return `${payment.amount < 0 ? '-' : ''}$${formatCurrency(payment.amount, true)}`
  }

  const getTransactionId = () => {
    if (!isNil(payment.external_transaction_id)) {
      return payment.external_transaction_id
    }

    return payment.payment_transaction_id
  }

  return (
    <html>
      <body style={bodyStyle}>
        {index === 0 ? (
          <div style={{width: '100%'}}>
            <div 
              style={payStubSection}
            >
              {`Payments`}
            </div>

            <div style={paymentDetailsTableHightlightRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Paid Date`}	
              </div>

              <div style={{
                ...paymentDetailsCenteredTableCell,
                // width: columnWidth.paidVia,
                flex: 1,
              }}>
                {`Paid Via`}
              </div>

              <div style={{
                ...paymentDetailsCenteredTableCell,
                // width: columnWidth.transactionId,
                flex: 1,
              }}>
                {`Transaction ID`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.fee,
              }}>
                {`Trans Fee`}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`Amount`}
              </div>
            </div>
          </div>
        ) : null}

        <div style={getRowStyles(index)}>
          <div style={{
            ...paymentDetailsTableFirstCell,
          }}>
            {payment.paid_date}
          </div>
          
          <div style={{
            ...paymentDetailsCenteredTableCell,
            // width: columnWidth.paidVia,
            flex: 1,
          }}>
            {payment.payment_company_name}
          </div>

          <div style={{
            ...paymentDetailsCenteredTableCell,
            // width: columnWidth.transactionId,
            flex: 1,
          }}>
            {getTransactionId()}
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.fee,
          }}>
            {getFee()}
          </div>

          <div style={{
            ...paymentDetailsTableCell,
            width: columnWidth.amount,
          }}>
            {getPayAmount()}
          </div>
        </div>
        
        {index === array.length - 1 ? (
          <div style={{width: '100%'}}>
            <div style={paymentDetailsTableTotalsRow}>
              <div style={{
                ...paymentDetailsTableFirstCell,
              }}>
                {`Total Payment(s)`}
              </div>

              <div style={{
                ...paymentDetailsCenteredTableCell,
                flex: 1,
              }}>
                {` `}
              </div>

              <div style={{
                ...paymentDetailsCenteredTableCell,
                flex: 1,
              }}>
                {` `}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.fee,
              }}>
                {` `}
              </div>

              <div style={{
                ...paymentDetailsTableCell,
                width: columnWidth.amount,
              }}>
                {`$`}{formatCurrency(totalAmount)}
              </div>
            </div>
          </div>
        ) : null}
      </body>
    </html>
  )
}

export default memo(PayStubPaymentsTable)
